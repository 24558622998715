form{
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .input-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &.small{
      width: 45%;
    }

    &.big{
      width: 100%;
    }

    input[type="text"], input[type="email"]{
      width: 100%;
      margin-bottom: 20px;
      border-radius: 18px;
      border: none;
      padding: 10px;
    }
  }

  #mail-block{
    opacity: 0;
  }

  #optInCheckbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transform: translateY(-70px);
  
    #optIn{
      width: unset;
    }

    label{
      margin-left: 10px;
      font-size: 14px;
      text-align: initial;
    }
  }
}

.rti--container{
  width: 100%;
  margin-top: 20px;
  border: none;

  .rti--input{
    width: 100%;
    border: none;
    border-radius: 18px;
    font-size: 0.8em;
    padding: 5px;

    &:focus-within{
      border: none;
      outline: none;
      border-color: unset !important;
      box-shadow: none !important;
    }
  }

  &:focus-within{
    border: none;
    outline: none;
    border-color: unset !important;
    box-shadow: none !important;
  }

  .rti--tag{
    color: black;
  }
}

@media screen and (min-width: 768px) {
  .rti--container{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  form{
    width: 90%;
  
    .input-container{
  
      &.small{
        width: 100%;
      }
  
      &.big{
        width: 100%;
      }

      label{
        margin-left: 10px;
        margin-bottom: 20px;
      }
  
      input{
        width: 100%;
        margin-bottom: 50px;
        border-radius: 18px;
        border: none;
        padding: 10px;
      }
    }
  }
}
