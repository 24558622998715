.text-container{
  text-align: center;
  max-width: 1000px;
  padding: 0 20px;
}

.appGrid{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  .gridL{
    background-color: #380082;
  }

  .gridR{
    justify-content: space-around !important;
    background-color: #7d0fff;
  }

  .gridL, .gridR{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: calc(48% - 50px);
  }
}

.border-container{
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0px;
  overflow-y: auto;
}

.theme-container, .artist-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.artist-container{
  margin-bottom: auto;
}

.btn-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  padding-bottom: 0px;

  &.center{
    justify-content: center;
    gap: 20px;
  }

  &>div{
    display: flex;
    flex-direction: row;

    &>:nth-child(1){
      transform: rotate(180deg);
      margin-right: 10px;
    }
  }
}

.poster-container{
  -webkit-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
}

@media screen and (min-width: 768px) {
  .appGrid{
    flex-wrap: unset;
  }

  .appGrid .gridL, .appGrid .gridR{
    width: calc(50% - 25px);
    height: calc(100% - 40px);

  }

  .btn-container{
    padding: unset;
  }

  .appGrid .gridR{
    justify-content: space-between !important;
  }

  .poster-container{
    height: 100% !important;
  }

  #posterresult{
    width: calc(100% - 25px);
    padding: 60px;
    flex-direction: row-reverse;
    justify-content: space-around !important;
  }

  .endResultContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .text-container{
    max-width: 90%;
  }

  .appGrid{
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    height: auto;
    .gridL, .gridR{
      width: calc(100% - 40px);
      height: calc(100vh - 65px);
    }

    #posterresult{
      height: 100%;
      justify-content: flex-start !important;
    }

    .endResultContent{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
