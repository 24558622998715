@font-face {
  font-family: 'recoletabold';
  src: url('../fonts/recoleta_bold-webfont.woff2') format('woff2'),
       url('../fonts/recoleta_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'recoletablack';
  src: url('../fonts/recoleta_black-webfont.woff2') format('woff2'),
       url('../fonts/recoleta_black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'recoletamedium';
  src: url('../fonts/recoleta_medium-webfont.woff2') format('woff2'),
       url('../fonts/recoleta_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'recoletaregular';
  src: url('../fonts/recoleta_regular-webfont.woff2') format('woff2'),
       url('../fonts/recoleta_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body, p{
  font-family: 'recoletaregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

h1, h2, h3, h4, h5, h6, .btn, .iconBtnRound{
  font-family: 'recoletabold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-transform: uppercase;
}

h1{
  margin-top: 0;
  font-size: 3.052em;
  line-height: 1.2;
  margin-bottom: 1em;
}

p{
  font-size: 1.125em;
  line-height: 1.5;
  margin-bottom: 1em;
}

.text-center{
  text-align: center;
}

@media screen and (max-width: 768px) {
  h1{
    font-size: 1.8em;
  }

  p{
    font-size: 0.8em;
  }
}
