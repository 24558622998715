.poster-content{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 40px);
  padding: 20px 40px;
  width: calc(100% - 80px);
}

  #postercontent-personal-text, #poster-content-info{
    padding: 0 20px;
  }

  #poster-content-artists{
    
    width: 100%;

    .artistName{
      margin: 0;
      text-align: center;
    }
  }

  .poster-content-topTitle{
    margin: 0;
    text-align: center;
  }

  #line-1{
    font-size: 1.3em;
  }

  #line-2{
    font-size: 1.1em;
  }

  #line-3{
    font-size: 1.6em;
  }

  .poster-content-static{
    margin: 0;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .poster-content-topTitle{
      font-size: 5vh;
    }

    .artistName{
      font-size: 3.5vh;
    }

    #line-1{
      font-size: 2.12vh;
    }
  
    #line-2{
      font-size: 1.7vh;
    }
  
    #line-3{
      font-size: 2.4vh;
    }
  }


  @media screen and (max-width: 768px) {
    .poster-content-topTitle{
      font-size: 3.4vh;
    }

    .artistName{
      font-size: 2.4vh;
    }

    #line-1{
      font-size: 1.4vh;
    }
  
    #line-2{
      font-size: 1.1vh;
    }
  
    #line-3{
      font-size: 1.6vh;
    }
  }
