.btn{
  text-transform: uppercase;
  text-decoration: none;
  padding: 14px 27px;
  background-color: #F22525;
  color: #F5F0E0;
  font-size: 20px;
  border: none;
}

.iconOnlyBtnRound{
  background-color: #F22525;
  border: none;
  border-radius: 40px;
  padding: 15px 15px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.iconBtnRound{
  text-transform: uppercase;
  text-decoration: none;
  background-color: #ffbac2;
  border: none;
  border-radius: 40px;
  padding: 13px 15px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000;

  &.red{
    background-color: #F22525;
    color: #F5F0E0;
  }

  &.alt{
    color: #F5F0E0;
  }

  > img {
    margin-left: 15px;
  }  
}
