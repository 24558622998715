html {
  // scroll-behavior: smooth;
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  overflow-x: hidden;
}

.app{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-image: url('../images/ruit_bg.png');
  background-size: cover;
  background-position: center;
  color: #F5F0E0;

  &.alt{
    width: 401%;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
  }

}

#userForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
  width: 0;
  height: calc(100% - 40px);
  padding: 20px 0;
  -webkit-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
}

#themeForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
  width: 0;
  height: 100%;
  -webkit-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
}

#artistForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
  width: 0;
  height: 100%;
  -webkit-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
}
#endResult{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 0;
  width: 0;
  height: 100%;
  -webkit-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 30px -8px rgba(0,0,0,0.75);
}

.logo-small{
  width: 20%;
}

.logo-big{
  width: 50%;
}

.information-block{
  position: absolute;
  z-index: 99999;
  top: 0;
  background-color: red;
  color: white;
  display: flex;
  flex-direction: row;
  >div{
    margin-right: 10px;
  }

  p{
    font-size: 0.1em;
  }
}

.divider{
  position: relative;
  top: 0;
  width: 100%;
  height: 50px;
  overflow: clip;
   img{
    height: 50px;
   }
}

@media screen and (min-width: 768px) {

  .divider{
    width: 50px;
    height: 100%;
    img{
      width: 100%;
      height: unset;
    }
  }
  #themeForm{
    width: 100%;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {

  .app{
    height: 100%;
    overflow: hidden;
  }

  section{
    overflow-y: auto;
  }

  .logo-small{
    width: 80%;
  }

  .logo-big{
    width: 100%;
  }
}

@media screen and (max-width: 280px) {
  .app{
    overflow-y: auto;
  }
  
}
