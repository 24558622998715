.ais-SearchBox{
  width: 100%;
  margin: 10px 0;
  .ais-SearchBox-form{
    margin-top: 0;
    width: 100%;

    .ais-SearchBox-input{
      border: none;
      border-radius: 20px;
      padding: 5px;
      width: 100%;
      
    }

    .ais-SearchBox-submit, .ais-SearchBox-reset{
      display: none;
    }
  }
}
