.block-item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  .block-img{
    position: relative;
    width: 100px;
    height: 100px;

    .overlay{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: rgba(6, 117, 0, 0.7);

      &.disabled{
        display: none;
      }
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .block-title{
    font-size: 1em;
  }
}
